<template>
  <div class="practiceOneStep">
    <div class="leftnav">
      <Step :active="5" />
    </div>
    <main>
      <div class="boxLeft">
        <div v-show="type === ''" class="background" v-for="(item, index) in textlist" :key="index">
          <img :src="textlist[index].wgjztpdz" :style="(currentIndex !== null ? index == currentIndex : index == 0) ? 'visibility:visible;' : ''" alt="">
        </div>
        <div class="background" v-show="type === 'all'">
          <img :src="details.wgjztpdz" alt="" style="visibility:visible;">
        </div>
        <div v-show="type === 'section'" class="background" v-for="(item, index) in textlist" :key="index">
          <img :src="textlist[index].wgjztpdz" :style="sectionIndex === index ? 'visibility:visible;' : ''" alt="">
        </div>
      </div>
      <div class="right">
        <div class="title">
          <p> {{ details.kwmc.substring(0,9) }} </p>
          <p> {{ details.kwmc.substring(9) }} </p>
        </div>
        <div class="box" id="box">
          <p>{{ titleTop }}</p>
          <p>{{ titleBottom }}</p><br/>
          {{ sectionTitle }}
        </div>
        <div class="right-footer" id="footer">
          <div class="wait" v-if="wait">评分中...</div>
          <!-- 动画 -->
          <div class="luyin" id="luyin"  v-if="isPress"></div>
          <div class="lyafter" v-if="result[currentIndex] && !isPress && !wait">
            <div class="afterbox">
              <p>恭喜你，本次获得</p>
              <el-rate
                v-model="result[currentIndex].total_score"
                disabled
                show-score
                disabled-void-color="#D0D0D0"
                :colors="['#FEA20B', '#FEA20B', '#FEA20B']"
                score-template="{value}">
              </el-rate>
            </div>
            <div class="score" id="score">
              <div>
                <span id="standard_score">{{ '标准度：' + result[currentIndex].standard_score + '%' }}</span>
                <span id="accuracy_score">{{ '准确度：' + result[currentIndex].accuracy_score + '%' }}</span>
              </div>
              <div>
                <span id="fluency_score">{{ '流利度：' + result[currentIndex].fluency_score + '%' }}</span>
                <span id="integrity_score">{{ '完整度：' + result[currentIndex].integrity_score + '%' }}</span>
              </div>
            </div>
          </div>
          <div class="bottom">
            <!-- 重 -->
            <div style="visibility:hidden;"></div>
            <!-- v-if="isPc" -->
            <div @click="touchclick">{{ isPress ? '结束跟读' : '点击跟读' }}</div>
            <!-- <div v-if="!isPc" @touchstart="touchstart" @touchend="touchend">{{ isPress ? '结束跟读' : '按住跟读' }}</div> -->
            <!-- 下一步，右箭头 -->
            <div class="nextsentence" v-if="(currentIndex && currentIndex == textlist.length - 1) || (currentIndex && type === 'all' && currentIndex === textlist.length) || (currentIndex && type === 'section' && currentIndex === textlist.length+1)" @click="next(1)"></div>
            <!-- 下一句 -->
            <div class="nextstep" v-else @click="next(2)"></div>
          </div>
        </div>
      </div>
    </main>
    <Header :header_data="header_data" :noClicking="true" />
    <!-- 选择练习和测验 -->
    <div class="lxorcy" v-if="isShow">
      <p class="title">选择学习环节</p>
      <div class="body">
        <img src="../../../assets/student/lximg.png" alt="" @click="toLink('practiceTwoStep')">
        <img src="../../../assets/student/cyimg.png" alt="" @click="toLink('testStep')">
      </div>
      <div class="footer">
        <div @click="toLink('practiceTwoStep')">进入练习</div>
        <div @click="toLink('testStep')">进入测验</div>
      </div>
    </div>
    <div class="mask" v-if="isShow"></div>
  </div>
</template>
<script>
import IseRecorder from '@/assets/js/index'
import Header from "../components/zjfheader.vue";
import Step from "../components/step.vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import { getCurrentInstance, nextTick, onBeforeUnmount } from 'vue'
import { ElMessage } from "element-plus"
import { useStore } from 'vuex'
import { getEnglishText, getTextContent, saveMyBreakpoint, getMyBreakpoint } from '@/api/student'
export default {
  components: {
    Header,
    Step,
  },
  setup() {
    const store = useStore()
    const { appContext } = getCurrentInstance()
    const that = appContext.config.globalProperties
    const data = reactive({
      details: { kwmc: '' },
      header_data: [],
      textlist: [{}],
      currentIndex: 0, // 当前句子索引
      learningIndex: 0, // 正在学的句子的索引
      isPress: false, // 当前是否正在按住
      result: [], // 成绩
      isPc: false,
      IseRecorder: null,
      titleTop: '基础练习1',
      titleBottom: '逐句背诵',
      audioVoice: 'y',
      type: '',
      sectionIndex: 0,
      sectionTitle: '', // 分段的时候，展示的标题
      sectionNumber: 0, // 分段的时候，第一段的长度
      timer: null,
      wait: false,
      notes: {},
      isShow: false
    })
    // 展示结果
    window.showResult = (res) => {
      data.result[data.currentIndex] = res
      data.wait = false
      data.notes.details.practiceOneStep = data.result
      preserve()
    }
    onMounted(() => {
      let params = {
        cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid,
        cjlx: 1,
        ddzt: 1,
        dyid: that.$route.query.dyid,
        xsid: store.state.userInfo.yhid,
        zid: that.$route.query.zid,
        zsdid: sessionStorage.getItem('zsdid')
      }
      getMyBreakpoint(params).then(ele => {
        if (ele.success) {
          let notes = ele.result.ddxx
          data.notes = JSON.parse(notes)
          data.notes.step = 'practiceOneStep'
          preserve()
          // 获取课文内容
          getTextContent({ kwid: that.$route.query.kwid }).then(res => {
            if(res.success) {
              if(res.result.length) {
                data.textlist = res.result
                if (!data.notes.details.practiceOneStep) {
                  nextTick(() => {
                    active(data.currentIndex)
                  })
                } else if (data.notes.details.practiceOneStep && data.notes.details.practiceOneStep.length <= data.textlist.length) {
                  data.currentIndex = data.notes.details.practiceOneStep.length-1
                  data.result = data.notes.details.practiceOneStep
                  nextTick(() => {
                    active(data.currentIndex)
                  })
                } else if (data.notes.details.practiceOneStep && data.notes.details.practiceOneStep.length > data.textlist.length) {
                  data.currentIndex = data.textlist.length-1
                  data.result = data.notes.details.practiceOneStep
                  if ((timeChange(data.textlist[data.textlist.length - 1][data.audioVoice+'sypwzz']) - timeChange(data.textlist[0][data.audioVoice+'sypwzq'])) < 180) {
                    allRecite()
                  } else {
                    sectionRecite()
                  }
                }
              }
            } else {
              ElMessage({
                message: res.resultDesc,
                type: 'warning'
              })
            }
          })
        } else {
          ElMessage({
            message: ele.resultDesc,
            type: 'warning'
          })
        }
      })
      data.IseRecorder = new IseRecorder()
      data.isPc = !(/(iPhone|iPad|iPod|iOS|Android)/i.test(window.navigator.userAgent))
      data.audioVoice = localStorage.getItem(`sound_${store.state.userInfo.realname}`) || 'y'
      // 获取课文发音
      getEnglishText({ kwid: that.$route.query.kwid }).then(res => {
        if(res.success) {
          data.details = res.result
          data.header_data = [
            { title: "图谱英语 — ", path: "newEnglish" },
            { title: that.$route.query.zmc + " — ", path: `/units?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}` },
            { title: that.$route.query.dymc + " — ", path: `/chapter?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}&dyid=${that.$route.query.dyid}&dymc=${that.$route.query.dymc}` },
            { title: res.result.kwmc, path: "" },
          ]
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
    })
    // 弹窗跳转
    const toLink =(name) => {
      data.notes.jclxcj = parseInt(data.result[data.currentIndex].total_score * 20)
      let params = {
        cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid,
        cjlx: 1,
        ddxx: JSON.stringify(data.notes),
        ddzt: 1,
        dyid: that.$route.query.dyid,
        xsid: store.state.userInfo.yhid,
        zid: that.$route.query.zid,
        zsdid: sessionStorage.getItem('zsdid')
      }
      saveMyBreakpoint(params).then(res => {
        console.log('学习记录保存成功')
        that.$router.replace({ name: name, query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid} })
      })
    }
    // 下一步
    function next (val) {
      if (data.isPress) {
        return false
      }
      if (data.wait) {
        return false
      }
      // 下一句
      if (val == 2) {
        if (data.result[data.currentIndex]) {
          if (data.type === 'section') {
            data.currentIndex++
            data.sectionIndex = data.sectionNumber
            data.sectionTitle = '请背诵后' + (data.textlist.length - data.sectionNumber) + '句'
            let audioText = ''
            for (let i = data.sectionNumber; i < data.textlist.length; i++) {
              audioText+=data.textlist[i].jznr
            }
            sessionStorage.setItem('audioText', audioText)
          } else {
            data.currentIndex++
            active(data.currentIndex) 
          }
        } else {
          ElMessage({
            message: '完成背诵才能到下一句哦~',
            type: 'warning'
          })
        }
        // 下一步
      } else {
        if (data.result[data.currentIndex]) {
          if (data.type) {
            // 练习2结束
            data.isShow = true
          } else {
            // 练习1结束
            if ((timeChange(data.textlist[data.textlist.length - 1][data.audioVoice+'sypwzz']) - timeChange(data.textlist[0][data.audioVoice+'sypwzq'])) < 180) {
              allRecite()
            } else {
              sectionRecite()
            }
          }
        } else {
          ElMessage({
            message: '完成背诵才能到下一步哦~',
            type: 'warning'
          })
        }
      }
    }
    // 时间转换秒数
    const timeChange = (time) => {
      return Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]) + Number(time.split(':')[2]) / 1000
    }
    // 全文背诵
    const allRecite = () => {
      data.titleTop = '基础练习2'
      data.titleBottom = '全文背诵'
      data.type = 'all'
      data.currentIndex++
      let article = ''
      data.textlist.forEach(ele => {
        article+=ele.jznr
      })
      sessionStorage.setItem('audioText', article)
    }
    // 分段背诵
    const sectionRecite = () => {
      data.titleTop = '基础练习2'
      data.titleBottom = '分段背诵'
      data.type = 'section'
      data.currentIndex++
      let seconds = 180 // 多少秒一段 // 临时
      let num = -1
      let arr = []
      data.textlist.forEach(ele => {
        if (ele.sfds) {
          num++
          arr[num] = { list: [], time: 0 }
        }
        arr[num].list.push(ele)
      })
      arr.forEach(ele => {
        ele.time = timeChange(ele.list[ele.list.length-1][data.audioVoice+'sypwzz']) - timeChange(ele.list[0][data.audioVoice+'sypwzq'])
      })
      let arrIndex = null
      let time = 0
      for (let i = 0;i < arr.length; i++) {
        time+=arr[i].time
        if (time > seconds) {
          arrIndex = i-1
          break
        } else {
          arrIndex = i
        }
      }
      let number = 0
      arr.forEach((ele, index) => {
        if (arrIndex >= index) {
          number+=ele.list.length
        }
      })
      let audioText = ''
      for (let i = 1; i <= number; i++) {
        audioText+=data.textlist[i-1].jznr
      }
      sessionStorage.setItem('audioText', audioText)
      data.sectionTitle = '请背诵前' + number + '句'
      data.sectionNumber = number
    }
    // 高亮
    const active = (index) => {
      sessionStorage.setItem('audioText', data.textlist[index].jznr)
      data.currentIndex = index
    }
    // 按住
    const touchstart = () => {
      data.wait = false
      data.isPress = true
      data.IseRecorder.start()
      if (data.type === 'section') {
        imgRun(data.sectionIndex === data.sectionNumber ? data.sectionNumber : 0)
      }
    }
    // 松手
    const touchend = () => {
      if (data.wait) {
        return false
      }
      data.isPress = false
      data.wait = true
      data.IseRecorder.stop()
      if (data.type === 'section') {
        imgStop(data.sectionIndex >= data.sectionNumber ? data.sectionNumber : 0)
      }
    }
    // 点击
    const touchclick = () => {
      if (data.isPress) {
        data.isPress = false
        data.wait = true
        data.IseRecorder.stop()
        if (data.type === 'section') {
          imgStop(data.sectionIndex >= data.sectionNumber ? data.sectionNumber : 0)
        }
      } else {
        data.wait = false
        data.isPress = true
        data.IseRecorder.start()
        if (data.type === 'section') {
          imgRun(data.sectionIndex === data.sectionNumber ? data.sectionNumber : 0)
        }
      }
    }
    //分段背诵的时候，左侧图片的轮播效果
    const imgRun = (index) => {
      let time = timeChange(data.textlist[index][data.audioVoice+'sypwzz']) - timeChange(data.textlist[index][data.audioVoice+'sypwzq'])
      clearTimeout(data.timer)
      data.sectionIndex = index
      data.timer = setTimeout(() => {
        if (index+1 === data.sectionNumber) {
          imgStop(0)
        } else if (index === data.textlist.length-1) {
          imgStop(data.sectionNumber)
        } else {
          imgRun(index+1)
        }
      }, time*1000)
    }
    // 分段背诵的时候，左侧图片的轮播效果停止
    const imgStop = (index) => {
      clearTimeout(data.timer)
      data.sectionIndex = index
    }
    // 保存进度
    const preserve = () => {
      let params = {
        cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid,
        cjlx: 1,
        ddxx: JSON.stringify(data.notes),
        ddzt: 1,
        dyid: that.$route.query.dyid,
        xsid: store.state.userInfo.yhid,
        zid: that.$route.query.zid,
        zsdid: sessionStorage.getItem('zsdid')
      }
      saveMyBreakpoint(params).then(res => {
        console.log('学习记录保存成功')
      })
    }
    return {
      ...toRefs(data),
      next,
      touchstart,
      touchend,
      touchclick,
      showResult,
      allRecite,
      sectionRecite,
      timeChange,
      imgRun,
      imgStop,
      preserve,
      toLink
    }
  },
};
</script>
<style lang="scss" scoped>
@media (max-height: 700px) {
  #score {
    margin: 0;
  }
}
.lxorcy {
  width: 21.406vw;
  height: 12.8125vw;
  background: url('../../../assets/student/selectbj.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding-top: 0.5vw;
  z-index: 7;
  .title {
    font-size: 1.25vw;
    color: #fff;
    text-align: center;
    position: relative;
    p {
      font-family: 'Times New Roman', Times, serif;
    }
  }
  .body {
    position: absolute;
    top: 4.0625vw;
    left: 3.489583vw;
    img {
      display: inline-block;
      width: 5.41666vw;
      height: 4.322916vw;
      cursor: pointer;
    }
    img:nth-child(1) {
      margin-right: 3.489583vw;
    }
  }
  .footer {
    width: 100%;
    position: absolute;
    height: 2.8125vw;
    bottom: 0.9375vw;
    div {
      width: 7.55208vw;
      height: 2.8125vw;
      background: url('../../../assets/student/selectbtn.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 1.041666vw;
      text-align: center;
      line-height: 2.8125vw;
      float: left;
      cursor: pointer;
    }
    div:nth-child(1) {
      position: absolute;
      left: 2.5vw;
    }
    div:nth-child(2) {
      position: absolute;
      right: 2.9166vw;
    }
  }
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.3);
  z-index: 6;
}
.practiceOneStep {
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/zjfstudyBanner.png") no-repeat;
  background-size: 100% 100%;
  .wait{
    font-size: 22px;
    text-align: center;
    padding-top: 50px;
  }
  .leftnav {
    height: 65%;
    position: absolute;
    left: 4.5vw;
    top: 8.5vw;
    z-index: 3;
  }
  main {
    width: 94.78%;
    height: 83.925%;
    background: url("../../../assets/student/wordbanner.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: .2vw .2vw .2vw 0;
    .boxLeft {
      width: 71.2vw;
      height: 100%;
      float: left;
      box-sizing: border-box;
      padding: 1vw 0 1vw 2vw;
      position: relative;
      .background {
        position: absolute;
        top: -2vw;
        left: 3.5vw;
        right: 0;
        bottom: -2vw;
        img {
          display: block;
          height: 100%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          visibility:hidden;
        }
      }
    }
    .right {
      height: 100%;
      width: 23.3vw;
      border-radius: 3.125vw;
      box-sizing: border-box;
      padding-top: 1vw;
      position: relative;
      .title {
        width: 100%;
        text-align: center;
        font-size: 1.6vw;
        font-weight: 600;
        p {
          font-family: 'Times New Roman', Times, serif;
        }
      }
      .box {
        width: 100%;
        position: absolute;
        top: 15%;
        bottom: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        font-size: 1.6vw;
        line-height: 2.5vw;
        font-weight: 600;
        p:nth-child(2) {
          // font-weight: 500;
        }
      }
      .right-footer {
        width: 100%;
        height: 30%;
        position: absolute;
        bottom: 0;
        .luyin {
          width: 7.708vw;
          height: 5vw;
          background: url('../../../assets/student/gifluyin.gif') no-repeat;
          background-size: 100% 100%;
          position: absolute;
          top: 33%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .lyafter{
          .afterbox {
            padding-top: 1vw;
            display: flex;
            justify-content: center;
            p {
              font-size: 1.3vw;
              float: left;
            }
            :deep(.el-rate) {
              float: left;
            }
            :deep(.el-rate__icon) {
              font-size: 1.7vw;
              margin: 0;
            }
            :deep(.el-rate__text) {
              display: none;
            }
          }
          .score {
            margin-top: 3%;
            display: flex;
            flex-direction: column;
            div {
              text-align: center;
              span {
                margin-right: 2%;
              }
              span:nth-child(2) {
                margin: 0;
              }
            }
          }
        }
        .bottom {
          position: absolute;
          bottom: 5%;
          width: 92%;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: space-around;
          div {
            cursor: pointer;
          }
          div:nth-child(1) {
            width: 3.645vw;
            height: 3.541vw;
            background: url('../../../assets/student/chong.png') no-repeat;
            background-size: 100% 100%;
          }
          div:nth-child(2) {
            width: 12.34vw;
            height: 3.75vw;
            background: url('../../../assets/student/startluyin.png') no-repeat;
            background-size: 100% 100%;
            color: #fff;
            font-size: 1.3vw;
            box-sizing: border-box;
            padding-right: 2vw;
            text-align: right;
            line-height: 3.75vw;
          }
          .nextstep {
            width: 4.0625vw;
            height: 3.9583vw;
            background: url('../../../assets/student/nextsentence.png') no-repeat;
            background-size: 100% 100%;
          }
          .nextsentence {
            width: 4.0625vw;
            height: 3.9583vw;
            background: url('../../../assets/student/next.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .active {
    color: #578EFF !important;
    font-size: 1.5vw !important;
  }
  .activeNext {
    color: #000000 !important;
    font-size: 1.4vw !important;
  }
  ::-webkit-scrollbar {
    width: .6vw;
  }
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 20px;
  }
  // 修改popover样式
  .el-popper.is-light {
    top: -10px !important;
  }
}
</style>